<template>
    <b-card title="Edit Affiliate Sharing">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Country -->
                    <b-col cols="12">
                        <b-form-group label="Select Country" label-for="country">
                            <validation-provider name="Country" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="country" v-on:change="fetchItems($event)"
                                        v-model="item.country" :options="countryOptions"
                                        :state="errors.length > 0 ? false : null" disabled>
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!--Product Name -->
                    <b-col cols="12">
                        <b-form-group label="Product Name" label-for="Product Name">
                            <validation-provider name="Product Name" rules="required|max:50" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="product_name" v-model="rows.text" :state="errors.length > 0 ? false : null"
                                        placeholder="Product Name" disabled/>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!--Discount -->
                    <b-col cols="12">
                        <b-form-group label="Discount" label-for="Discount">
                            <validation-provider name="Discount" rules="required|max:50" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="discount" v-model="item.discount" :state="errors.length > 0 ? false : null"
                                        placeholder="Discount" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- Discount Type -->
                    <b-col cols="12">
                        <b-form-group label="Discount Type" label-for="Discount Type">
                            <validation-provider name="Discount Type" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ChromeIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="discount_type"
                                        v-model="item.discount_type" :options="discountOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!--Cool Down Days -->
                    <b-col cols="12">
                        <b-form-group label="Cool Down Days" label-for="Cool Down Days">
                            <validation-provider name="Cool Down Days" rules="required|max:50" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="cool_down_days" v-model="item.cool_down_days" :state="errors.length > 0 ? false : null"
                                        placeholder="Cool Down Days"/>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required,
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            item:{},
            rows:[],
            subscriptions: [],
            category: '',
            components: {},
            sid: '',
            cart: false,
            random_id: "",
            slug: "",
            name: '',
            description: '',
            previewImg: '',
            meta_title: '',
            meta_description: '',
            meta_keyword: '',
            profileImg: '',
            discount: '',
            selecteddiscount: null,
            selectedCountry: null,
            selectedProduct: null,

            discountOptions: [
                { value: null, text: 'Please select Discount' },
                { value: "percent", text: 'Percent' },
                { value: "fixed", text: 'Fixed' },

            ],

            productOptions: [
                { value: null, text: 'Please select Product' },
            ],

            countryOptions: [
                { value: null, text: 'Please select a country' },
            ]
        }
    },
    created() {
        this.getCountries();
        this.getItem();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        reset() {
                this.name = '',
                this.description = '',
                this.previewImg = '',
                this.meta_title = '',
                this.meta_description = '',
                this.meta_keyword = '',
                this.profileImg = '',
                this.selectedoptions = null,
                this.selectedCountry = null;
        },

        getItem()
            {
                var id = this.$route.params.id;

                this.rows = {};
                // this.item = {};
      var i = 1;

      db.collection("affiliates_discount")
            //   .where("doc.id", "==", doc.data().product)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.collection("subscriptions")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((docs) => {
                  if(doc.id == id && docs.id == doc.data().product){
                    this.item = doc.data();
                    this.rows = {
                            value: docs.id,
                            text: docs.data().title,
                        };
                  console.log(docs.id, " => ", this.rows);
                  console.log(doc.id, " => ", doc.data());

                  i++;
                  store.dispatch('app/commitDeactivateLoader');
                }
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
                store.dispatch('app/commitDeactivateLoader');
              });
          });
        })

                // db.collection('affiliates_discount').doc(id).get().then((doc) => {
                //     console.log(doc.data());
                //     this.item = doc.data();
                //     this.selc = doc.data().checkedCategories;
                //     this.selectedFlavor =  parseInt(doc.data().checkedCategories);
                //     this.previewImg = doc.data().image;
                // })
                // console.log(doc.id, " => ", doc.data())
            },

        getCountries() {
            this.countries = [];

            db.collection("countries")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                            slug: doc.data().slug,
                        });
                        // console.log(doc.id, " => ", doc.data());

                    });


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        fetchItems(event) {
            this.productOptions = [
            { value: null, text: 'Please select Product' },
            ],
            // alert(event)

            db.collection("subscriptions")
                .where("country", "==", event)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {

                        console.log(doc.id, " => ", doc.data());

                        var discountPrice = doc.data().price - doc.data().price * doc.data().discount / 100;
                        var vat = discountPrice + discountPrice * doc.data().vat / 100;

                        this.productOptions.push({
                            value: doc.id,
                            text: doc.data().title,
                            tag_line: doc.data().tag_line,
                            tags: doc.data().tag,
                            description: doc.data().description,
                            image: doc.data().image,
                            price: doc.data().price,
                            discount: doc.data().discount,
                            discountPrice: discountPrice,
                            vat: vat,
                            created: doc.data().created,
                        });

                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

                db.collection("psetings").doc('TrIXL7XKq9WwAwf1sVhB')
                .get()
                .then((doc) => {
                this.components = doc.data();
                })
                .catch((error) => {
                console.log("Error getting documents: ", error);
                });
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.submit();
                }
            })
        },

        submit() {
            console.log(this.selectedCountry,this.selectedProduct,this.selecteddiscount,this.discount)
            store.dispatch('app/commitActivateLoader');
            var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
            var id = this.$route.params.id;
            db.collection("affiliates_discount")
            .doc(id)
                .update({
                    discount_type: this.item.discount_type,
                    discount: this.item.discount,
                    cool_down_days: this.item.cool_down_days,
                    created: date,
                    modified: date,
                })
                .then((doc) => {
                    console.log("Updated Successfully!");
                    store.dispatch('app/commitDeactivateLoader');
                    this.$router.push({ name: 'affiliate-sharing-list' });
                })
            // var id = localStorage.getItem("userSessionId");
            // console.log('rrr ' + id);
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>